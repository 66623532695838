@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    font-family: 'Open Sans', sans-serif;
}
.modal-login-title {
    font-family: "Open Sans";
    font-size: 16px;
}

.google-button {
    border-color: #de3f2a !important;
    color: #de3f2a !important;
}

.google-button:hover {
    color: white !important;
    background-color: #de3f2a !important;
}

.facebook-button {
    border-color: #345aa6 !important;
    color: #345aa6 !important;
}

.facebook-button:hover {
    color: white !important;
    background-color: #345aa6 !important;
}

.modal-backdrop.show {
    opacity: .70
}
.sort-dropdown {
    left: 0 !important;
}
.modal-body {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

html, body, #root {
    height: 100%;
    background-color: #f5f8fa;
}

.layout-container {
    overflow: auto;
    height: 100%;
}

.layout-row {
    height: 100%
}

.layout-loader {
    height: 100%
}

/* The slow way */
.card-hover {
    transition: box-shadow 0.1s ease-in-out;
    transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);

}
.card-hover:hover {
    box-shadow: 0 4px 6px rgba(50,50,93,.05), 0 1px 3px rgba(0,0,0,.04);
}

.box:hover::after {
    opacity: 1;
}




.side-menu-container {
    box-shadow: -19px 2px 62px 0 rgba(0, 0, 0, 0.16);
    background-color: #fefeff;
    padding: 40px 20px;
    -webkit-flex: 0 0 320px;
            flex: 0 0 320px;
    margin-left: -15px;
    float: left;
}

.side-menu-collapse {
    margin-left: 15px;
    cursor: pointer;
}

.side-bar-collapse-icon {
    margin-top: 10px;
    font-size: 22px;
}

.side-bar-logo {
    margin-left: 35px;
}

/* DayPicker styles */

.DayPicker {
    display: inline-block;
    font-size: 1em;
}

.DayPicker-wrapper {
    position: relative;
    outline: none;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-bottom: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-Months {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 0 5px;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;

    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
}

.DayPicker-NavButton:hover {
    opacity: 0.8;
}

.DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.1em;
    padding: 0 0.5em;
    text-align: left;
}

.DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8B9898;
    text-align: center;
    font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    padding: 0.4em 0.4em;
    border-radius: 10%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

/* Default modifiers */

.DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
    background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0;
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.selected-date {
    margin-bottom: 0px;
}

._loading_overlay_datepicker_overlay {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

._loading_overlay_datepicker_content {
    color: black;
    font-family: 'Open sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.step-bar-container {
    margin-top: 50px;
    margin-left: 30px;
}

.rc-steps-item-content {
    margin-top: 0px;
}

.rc-steps-item-title {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -1.34px;
    text-align: left;
    color: #686868;
}

.rc-steps-item-description {
    font-size: 13px;
}

.rc-steps-item-tail {
    margin-left: -2px;
}

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    line-height: 8px;
    text-align: center;
}

.icon-step {
    font-size: 12px;
}
.nav-top-content {
    padding-top: 20px;
    background-color: white;
}

.navbar-toggler {
    border: none
}

.navbar-brand {
    margin-right: 0;
}

.nav-top-container {
    padding-right: 0px;
    padding-left: 0px;
}

.nav-top-sign-in {
    margin-left: 10px;
}

.nav-top-toggle-icon {
    font-size: 20px;
}

.nav-top-user {
    margin-right: 20px;
    margin-left: 40px;
    font-size: 13px;
    font-weight: bold;
}

.nav-top-user-card {
    padding: 10px 20px;
    box-shadow: none;
    border-radius: 6px;
}

.nav-top-location {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: contents;
    padding-top: 20px;
}

.nav-top-location-uf {
    text-decoration: underline;
    margin-right: 8px;
    margin-left: 8px;
}

.nav-top-location-icon {
    margin-right: 4px;
    margin-top: 3px;
}

.nav-top-location-caret {
    margin-top: 5px;
    font-size: 15px;
}

.nav-top-logo {
    height: 40px
}

.nav-top-current-user-avatar {
    height: 30px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -15px;
}

.nav-top-user-email {
    margin-left: 10px
}

.nav-top-user-options-icon {
    margin-right: -15px;
    opacity: 0.5;
    margin-left: 15px;
}

.dropdown {
    top: 10px;
    left: 80px;
}

@media (min-width: 768px) {
    .nav-top-content {
        background-color: transparent;
    }
}
