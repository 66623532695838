.nav-top-content {
    padding-top: 20px;
    background-color: white;
}

.navbar-toggler {
    border: none
}

.navbar-brand {
    margin-right: 0;
}

.nav-top-container {
    padding-right: 0px;
    padding-left: 0px;
}

.nav-top-sign-in {
    margin-left: 10px;
}

.nav-top-toggle-icon {
    font-size: 20px;
}

.nav-top-user {
    margin-right: 20px;
    margin-left: 40px;
    font-size: 13px;
    font-weight: bold;
}

.nav-top-user-card {
    padding: 10px 20px;
    box-shadow: none;
    border-radius: 6px;
}

.nav-top-location {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: contents;
    padding-top: 20px;
}

.nav-top-location-uf {
    text-decoration: underline;
    margin-right: 8px;
    margin-left: 8px;
}

.nav-top-location-icon {
    margin-right: 4px;
    margin-top: 3px;
}

.nav-top-location-caret {
    margin-top: 5px;
    font-size: 15px;
}

.nav-top-logo {
    height: 40px
}

.nav-top-current-user-avatar {
    height: 30px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -15px;
}

.nav-top-user-email {
    margin-left: 10px
}

.nav-top-user-options-icon {
    margin-right: -15px;
    opacity: 0.5;
    margin-left: 15px;
}

.dropdown {
    top: 10px;
    left: 80px;
}

@media (min-width: 768px) {
    .nav-top-content {
        background-color: transparent;
    }
}