.side-menu-container {
    box-shadow: -19px 2px 62px 0 rgba(0, 0, 0, 0.16);
    background-color: #fefeff;
    padding: 40px 20px;
    -ms-flex: 0 0 320px;
    flex: 0 0 320px;
    margin-left: -15px;
    float: left;
}

.side-menu-collapse {
    margin-left: 15px;
    cursor: pointer;
}

.side-bar-collapse-icon {
    margin-top: 10px;
    font-size: 22px;
}

.side-bar-logo {
    margin-left: 35px;
}
