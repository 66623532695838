.step-bar-container {
    margin-top: 50px;
    margin-left: 30px;
}

.rc-steps-item-content {
    margin-top: 0px;
}

.rc-steps-item-title {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -1.34px;
    text-align: left;
    color: #686868;
}

.rc-steps-item-description {
    font-size: 13px;
}

.rc-steps-item-tail {
    margin-left: -2px;
}

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    line-height: 8px;
    text-align: center;
}

.icon-step {
    font-size: 12px;
}