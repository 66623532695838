.modal-login-title {
    font-family: "Open Sans";
    font-size: 16px;
}

.google-button {
    border-color: #de3f2a !important;
    color: #de3f2a !important;
}

.google-button:hover {
    color: white !important;
    background-color: #de3f2a !important;
}

.facebook-button {
    border-color: #345aa6 !important;
    color: #345aa6 !important;
}

.facebook-button:hover {
    color: white !important;
    background-color: #345aa6 !important;
}

.modal-backdrop.show {
    opacity: .70
}