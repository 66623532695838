html, body, #root {
    height: 100%;
    background-color: #f5f8fa;
}

.layout-container {
    overflow: auto;
    height: 100%;
}

.layout-row {
    height: 100%
}

.layout-loader {
    height: 100%
}

/* The slow way */
.card-hover {
    transition: box-shadow 0.1s ease-in-out;
    -webkit-transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);

}
.card-hover:hover {
    box-shadow: 0 4px 6px rgba(50,50,93,.05), 0 1px 3px rgba(0,0,0,.04);
}

.box:hover::after {
    opacity: 1;
}



